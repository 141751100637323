import React from "react";

import config from "../../data/config.json";
import devIcons from "devicon/devicon.json";

import {
    Tech, TechList, UserDetails
} from "./About.styled";

const LanguageList = () => {
    return (
        <UserDetails>
            <h1>Languages</h1>

            <TechList>
                {config.tech_skills.map((skill) => <Language key={skill} skill={skill} />)}
            </TechList>
        </UserDetails>
    )
}

const Language = (props) => {
    const { skill } = props;

    const icon = devIcons.find(ico => ico.name.toLowerCase() === skill.toLowerCase());
    const className = `devicon-${skill}-${icon.versions.font[0]} colored`;
    const link = `https://github.com/topics/${skill}`;

    if (!icon) {
        console.error("CANNOT FIND ICON FOR ", skill);
        return null;
    }

    return (
        <Tech
            key={skill}
            hoverColor={icon.color}
            href={link}>
            <i className={className} />
        </Tech>
    )
}

export { LanguageList };