import React from "react";

import {
    StyledAboutSection, UserDetails
} from "./About.styled";
import { user } from "../../data/config.json";
import { LanguageList } from "./LanguageList";

const AboutSection = () => {


    return (
        <StyledAboutSection id="about">

            <UserDetails>
                <h1> Who Am I? </h1>
                <p>
                    {user.about}
                </p>
            </UserDetails>


            <LanguageList />

        </StyledAboutSection>
    )
};



export default AboutSection;