import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import MainLayout from './components/layout/main';
import theme from './config/theme';
import { GlobalStyle } from './globalStyle';

import "./styles/main.scss";

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <MainLayout />
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
