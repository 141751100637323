import styled from "styled-components";

const ProjectSection = styled.section`
    display        : flex;
    flex-direction : column;
    justify-content: space-between;
    width          : 90%;
    max-width      : 960px;
    margin         : 0 auto;

    @media only screen and (min-width: $cut) {
        width: 80%;
    }
`;

const Project = styled.div`
    margin: 20px auto;
    
    h5 {
        margin: 0;
    }

    p {
        margin-right: 5px;
    }
`;

const ProjectImageWrap = styled.div`

    width: 100%;

    @media only screen and (min-width:  ${({ theme }) => theme.cut}) {
        float: ${({ float }) => float};
        width: 48%;
    }

    img {
        max-width    : 100%;
        height       : auto;
        border-radius: 5px;

        @media only screen and (min-width:  ${({ theme }) => theme.cut}) {
            float: ${({ float }) => float};
        }
    }
`;

const ProjectContent = styled.div`
    margin-top: -5%;

    display: flex;
    flex-direction: column;
    align-items: stretch;

    h3 {
        display: flex;
        justify-content: center;
    }

    ul {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        
        li {
            color    : ${({ theme }) => theme.white};
            font-size: 1.1rem;
            list-style: none;

            background-color: ${({ theme }) => theme.grey};
            padding: 10px;
            border-radius: 10vw;
            margin: 5px;
        }

    }

    @media only screen and (min-width:  ${({ theme }) => theme.cut}) {
            width: 48%;
            float: ${({ float }) => float};
    }
`;

const ProjectLink = styled.a`
    display         : inline-block;
    margin          : 10px 0px;
    padding         : 5px;
    color           : ${({ theme }) => theme.white};
    background-color: transparent;
    border          : 1px solid ${({ theme }) => theme.white};
    border-radius   : 10px;
    text-align      : center;
    outline         : none;
    text-decoration : none;
    cursor          : pointer;
    transition      : color 0.3s ease-out,
        background-color 0.3s ease-out,
        border-color 0.3s ease-out;

    &:hover {
        background-color: ${({ theme }) => theme.purple};
        border-color    : ${({ theme }) => theme.purple};
        color           : ${({ theme }) => theme.main};
        transition      : color 0.3s ease-in,
            background-color 0.3s ease-in,
            border-color 0.3s ease-in;
    }

    &:active {
        background-color: ${({ theme }) => theme.purple};
        border-color    : ${({ theme }) => theme.purple};
        color           : ${({ theme }) => theme.main};
        transition      : color 0.3s ease-in,
            background-color 0.3s ease-in,
            border-color 0.3s ease-in;
    }
`;

const ProjectCollaborators = styled.ul`
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: flex-start;

    li {
        display: flex;
        flex-direction: column;
        margin: 10px;

        width: 90px;

        a, p {
            color: ${({ theme }) => theme.white};
            text-align: center;
        }

        img {
            width: 100%;
            margin-bottom: 5px;
        }
    }
`;

export {
    ProjectSection,
    Project,
    ProjectImageWrap,
    ProjectContent,
    ProjectLink,
    ProjectCollaborators
};