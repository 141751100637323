const theme = {
    "main": "#181a1b",
    "sec": "#4B5664",
    "purple": "#54516A",
    "white": "#FFF",
    "grey": "#8A8A8A",
    "cut": "950px",
};

export default theme;
