import React from "react";

import { UserDetails } from "../about/About.styled";
import {
    Project,
    ProjectContent,
    ProjectImageWrap,
    ProjectLink,
    ProjectSection,
    ProjectCollaborators
} from "./Projects.styled";

import projects from "../../data/projects.json";
import Avatar from "./components/Avatar";
import ProfileLink from "./components/ProfileLink";
let left = true;

const Projects = () => {
    return (
        <ProjectSection id="projects">

            <UserDetails>
                <h1> Personal Projects </h1>
            </UserDetails>

            {
                projects.map(project => {
                    left = !left;
                    return (
                        <Project key={project.name}>
                            <ProjectImageWrap float={left ? "left" : "right"}>
                                <img alt={`${project.name}`} src={project.image} />

                                {(project.collaborators && project.collaborators.length > 0) && (
                                    <ProjectCollaborators>
                                        {project.collaborators.map(collaborator => (
                                            <li key={collaborator.name}>
                                                <ProfileLink user={collaborator}>
                                                    {/* {collaborator.avatar && <img src={collaborator.avatar} alt={collaborator.name} />} */}
                                                    <Avatar user={collaborator} />
                                                    {collaborator.name}
                                                </ProfileLink>
                                            </li>
                                        ))}
                                    </ProjectCollaborators>
                                )}
                            </ProjectImageWrap>

                            <ProjectContent float={left ? "right" : "left"} >
                                <h3>{project.name}</h3>

                                {(project.skills && project.skills.length > 0) && (
                                    <ul>
                                        {project.skills.map(skill => <li key={skill}>{skill}</li>)}
                                    </ul>
                                )}

                                <p>{project.description}</p>
                                <ProjectLink href={project.source || "#"}>Check it out</ProjectLink>
                            </ProjectContent>
                        </Project>
                    );
                })
            }

        </ProjectSection>
    )
};

export { Projects };
