import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

html {
    scroll-behavior: smooth !important;
    background-color: ${({ theme }) => theme.main} !important;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    margin: 0;
    padding: 0;
    font-size: 100%;
    line-height: 1.5;
    
    color: ${({ theme }) => theme.white};
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

article,
aside,
figcaption,
figure,
footer,
header,
nav,
section {
    display: block;
}

h1,
h2,
h3,
h4,
stong {
    margin-top: 1em;
    padding-top: 1em;
    line-height: 1.25;
    color: ${({ theme }) => theme.white};
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.5rem;
}

h4 {
    font-size: 1rem;
}

ul,
ol {
    margin: 1em 0;
    padding-left: 40px;
}

p,
figure {
    margin: 1em 0;
}

a img {
    border: none;
}

sup,
sub {
    line-height: 0;
}
`;

export { GlobalStyle };