import React from "react";

import {
    StyledSocialIconsA,
    StyledSocialIconI,
    StyledSocialIconImg
} from "./Header.styled";

import aesjs from "aes-js";

const key = aesjs.utils.utf8.toBytes("58703272357538782F413F4428472B4B");

// const encryptTextAndConsoleLogHex = (str) => {
//     var textBytes = aesjs.utils.utf8.toBytes(str);

//     // The counter is optional, and if omitted will begin at 1
//     var aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5));
//     var encryptedBytes = aesCtr.encrypt(textBytes);

//     // To print or store the binary data, you may convert it to hex
//     var encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);
//     console.log(encryptedHex);
// }

const SocialIcon = (props) => {

    const { label, link, icon, icon_svg, name } = props;

    const ourIcon = icon ?
        <StyledSocialIconI className={`fa ${icon}`} aria-hidden="true" /> :
        <StyledSocialIconImg dangerouslySetInnerHTML={{ __html: icon_svg }}></StyledSocialIconImg>;

    let ourLink = link;
    let ourLabel = label;
    if (link.startsWith("mailto:")) {
        var text = link.replace("mailto:", "");

        var encryptedBytes = aesjs.utils.hex.toBytes(text);
        // The counter mode of operation maintains internal state, so to
        // decrypt a new instance must be instantiated.
        var aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5));
        var decryptedBytes = aesCtr.decrypt(encryptedBytes);

        // Convert our bytes back into text
        var decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
        ourLink = `mailto:${decryptedText}`;
        ourLabel = `${name}: ${decryptedText}`;
    }

    return (
        <StyledSocialIconsA
            aria-label={ourLabel}
            target="_blank"
            rel="noreferrer"
            href={ourLink}>

            {ourIcon}
        </StyledSocialIconsA>
    )
};

export default SocialIcon;
