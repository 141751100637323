import styled from "styled-components";

const Footer = styled.footer`
  background: ${({ theme }) => theme.main};
  padding: 10px 0;
  margin-top: 100px;
  text-align: center;
  color: ${({ theme }) => theme.white};

  .love {
      color: red;
  }

  a {
    text-decoration: none;
    margin: 0;
    color: ${({ theme }) => theme.white};
    &:hover{
      color: ${({ theme }) => theme.sec};
    }
    &:active {
      color: ${({ theme }) => theme.sec};
    }
  }

`;

export {
    Footer
}