import React from "react";
import Particles from "react-tsparticles";

import {
    ParicleContainer,
    HeaderContainer,
    Title,
    Description,
    SocialLinks,
    HeaderLink,
    HeaderLinks,
    DownButton,
    DownIcon,
} from "./Header.styled";

import config from "../../data/config.json";
import socials from "../../data/socials.json";
import SocialIcon from "./SocialIcons";
import particleConfig from "../../data/particles.json";

const Header = () => {

    const localSocials = config.socials;

    const mappedSocials = Object.keys(localSocials).map(socialName => {
        const socialProfile = localSocials[socialName];
        const socialData = socials[socialName];

        if (socialData == null || socialData === undefined) {
            console.error(`${socialName} doesn't exist in data/socials.json. Cannot add it`);
            return null;
        }

        return (
            <SocialIcon
                key={socialName}
                link={`${socialData.profile_url_prefix}${socialProfile}`}
                label={`${socialData.name}: ${socialProfile}`}
                {...socialData} />
        )
    });

    return (
        <ParicleContainer>
            <HeaderContainer>
                <h1>
                    <Title>{config.user.name}</Title>
                    <Description>{config.user.description}</Description>
                </h1>
                <SocialLinks>
                    {/* <a aria-label="Send email" href="#"><i class="icon fa fa-envelope"></i></a>
                    <a aria-label="My Twitter" target="_blank" href="#"><i class="icon fa fa-twitter" aria-hidden="true"></i></a>
                    <a aria-label="My Google Plus" target="_blank" href="#"><i class="icon fa fa-google-plus" aria-hidden="true"></i></a>
                    <a aria-label="My Github" target="_blank" href="#"><i class="icon fa fa-github-alt" aria-hidden="true"></i></a> */}
                    {mappedSocials}
                </SocialLinks>
                <HeaderLinks>
                    <HeaderLink href="#about" data-scroll>About Me</HeaderLink>
                    <HeaderLink href="#projects" data-scroll>Projects</HeaderLink>
                </HeaderLinks>
            </HeaderContainer>

            <DownButton
                href="#about">
                <DownIcon
                    className="fa fa-chevron-down"
                    aria-hidden="true" />
            </DownButton>


            <Particles
                container={ParicleContainer}
                width="100%"
                height="100%"
                options={particleConfig} />

            {/* <a className="down" href="#about" data-scroll><i class="icon fa fa-chevron-down" aria-hidden="true"></i></a> */}
        </ParicleContainer>
    )
};

export default Header;