import styled from "styled-components";

// function padZero(str, len) {
//     len = len || 2;
//     var zeros = new Array(len).join('0');
//     return (zeros + str).slice(-len);
// }

function invertColor(hex, bw) {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
        // http://stackoverflow.com/a/3943023/112731
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
            ? '#000000'
            : '#FFFFFF';
    }
    return `rgba(${(255 - r)}, ${(255 - g)}, ${(255 - b)}, 0.5)`;
    // invert color components
    // r = (255 - r).toString(16);
    // g = (255 - g).toString(16);
    // b = (255 - b).toString(16);
    // // pad each with zeros and return
    // return "#" + padZero(r) + padZero(g) + padZero(b);
}

const StyledAboutSection = styled.section`
  width: 90%;
  margin: 0 auto;
  max-width: 960px;

  @media only screen and (min-width: ${({ theme }) => theme.cut}) {
    width: 80%;
  }
`;

const Tech = styled.a`
    margin: 10px;
    color: ${({ theme }) => theme.white};
    text-align: center;
    padding: 10px;
    transition: all .7s;
    text-decoration: none;

    border-radius: 25%;

    i {
        font-size: 1em;

        @media only screen and (min-width: ${({ theme }) => theme.cut}) {
            font-size: 1.33333em;
        }
    }

    &:hover {
        color: ${({ hoverColor }) => hoverColor};
        background-color: ${({ hoverColor }) => invertColor(hoverColor)};
    }
`;

const TechList = styled.div`
  text-align: center;
  font-size: 60px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  p {
    font-size: 16px;
  }
  @media only screen and (min-width: ${({ theme }) => theme.cut}) {
    font-size: 75px;
  }
`;

const UserDetails = styled.div`
  text-align: center;
  margin: 5px auto;
`;

export {
    StyledAboutSection,
    UserDetails,
    Tech,
    TechList,
};
