import React from "react";
import AboutSection from "../about/About";
import { FooterSection } from "../footer/Footer";
import Header from "../header/Header";
import { Projects } from "../projects/Projects";

/*
<!DOCTYPE html>
<html lang="en">
  {% include head.html %}
  <body>
      <section id="projects">
        {% include projects.html %}
      </section>
        {{ content }}
  </body>
*/

const MainLayout = () => {
    return (
        <>
            <Header />
            <AboutSection />
            <Projects />
            <FooterSection />
        </>
    )
}

export default MainLayout;
