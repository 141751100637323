import styled from "styled-components";

const ParicleContainer = styled.div`
  background: ${({ theme }) => theme.main};
  display: flex;
  vertical-align: bottom;
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  border-bottom: ${({ theme }) => theme.grey} solid 1px;
  
  #tsparticles {
    width:100% !important;
    height:100vh !important;
  }
`

const HeaderContainer = styled.div`
    z-index: 1;
    text-align: center;
    color:${({ theme }) => theme.white};
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0 auto;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
`

const Title = styled.span`
    font-size: 50px;
    display: block;
    line-height: 1;
    color: ${({ theme }) => theme.white};
    @media only screen and (min-width: ${({ theme }) => theme.cut}){
        margin: 0;
        font-size: 100px;
    }
`;

const Description = styled.span`
    font-size: 20px;
    display: block;
    line-height: 1;
    color: ${({ theme }) => theme.white};
    margin-top: 10px;
    @media only screen and (min-width: ${({ theme }) => theme.cut}){
        font-size: 40px;
    }
`;

const HeaderLinks = styled.div`
    margin: 10px;
`;

const HeaderLink = styled.a`
    color: ${({ theme }) => theme.white};
    text-decoration: none;
    font-size: 15px;
    margin: 10px;
    @media only screen and (min-width: ${({ theme }) => theme.cut}){
        font-size: 20px;
    }
`;

const SocialLinks = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
`;

const StyledSocialIconsA = styled.a`
    text-align: center;
    color: ${({ theme }) => theme.white};
    width: 20px;
    height: 20px;
    padding: 10px;
    margin: 5px;
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.white};
    transition: all .7s;

    svg {
        path {
            fill: ${({ theme }) => theme.white};
        }
    }


    &:hover {
        color: ${({ theme }) => theme.main};
        background: ${({ theme }) => theme.white};

        svg {
            path {
                fill: ${({ theme }) => theme.main};
            }
        }
    }
    
    &:active {
        color: ${({ theme }) => theme.main};
        background: ${({ theme }) => theme.white};
    }

    @media only screen and (min-width: ${({ theme }) => theme.cut}) {
        width: 35px;
        height: 35px;
    }

`;
const StyledSocialIconImg = styled.span`
    width: 1.333em;
    height: 1.333em;

    @media only screen and (min-width: ${({ theme }) => theme.cut}) {
        width: 2em;
        height: 2em;
    }
`;
const StyledSocialIconI = styled.i`
    font-size: 1.333em ;

    @media only screen and (min-width: ${({ theme }) => theme.cut}) {
        font-size: 2em;
    }
`;

const DownButton = styled.a`
    color: ${({ theme }) => theme.white};
    position: absolute;
    bottom: 25px;
    width: 100%;
    margin: 0 auto;
    display: block;
    text-align: center;
    font-size: 30px;
    cursor: pointer;
`;

const DownIcon = styled.i`
    position: absolute;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    fill: ${({ theme }) => theme.white};
    -webkit-animation: pulse 1.3s infinite;
    animation: pulse 1.3s infinite;
    &:hover {
        color: ${({ theme }) => theme.sec};
    }
    &:active {
        color: ${({ theme }) => theme.sec};
    }
`;

export {
    ParicleContainer,
    HeaderContainer,

    Title,
    Description,

    SocialLinks,
    HeaderLinks,
    HeaderLink,

    StyledSocialIconI,
    StyledSocialIconImg,
    StyledSocialIconsA,

    DownButton,
    DownIcon,

};

// .down {
//     color: ${({ theme }) => theme.white};
//     position: absolute;
//     bottom: 25px;
//     width: 100 %;
//     margin: 0 auto;
//     display: block;
//     text - align: center;
//     font - size: 30px;
//     cursor: pointer;
//   .icon {

// }
