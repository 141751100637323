import React from "react";

import { user } from "../../data/config.json";
import { Footer } from "./Footer.styled";

const FooterSection = () => {

    return (
        <Footer>
            <p>
                Built with ReactJS and <span className="love">❤</span> by <a href="https://github.com/TGRHavoc">{user.name}</a>
            </p>
        </Footer>
    )
};


export { FooterSection };